<template>
    <div>
        <div class="datum-row">
            <div class="datum-title">
                <p>资料设置</p>
            </div>
            <div class="datum">
                    <div style=" display:flex; width:750px;border-left: 0px solid #8C45D3;padding-left:30px">
                        <div style=" width:250px;text-align: center;">
                            <el-upload
                                    action="https://gz.zxw233.com/api/common/upload_file"
                                    class="upload-demo"
                                    :http-request="httpRequest"
                                    accept=".png,.jpg"
                                    :show-file-list="false"
                                    :on-change="uploadChange"
                            >
                                <div class="photo">
                                    <img :src="url_prefix + userList.avatar" style="margin:0 auto;width:120px;height:120px;border-radius: 50%" v-if="userList.avatar">
                                </div>
                            </el-upload>
                            <br>
                            <span style="font-weight:bold">修改头像</span>
                        </div>
                        <div>
                            <span style="border-left: 3px solid #4C94FF;font-weight:bold">&nbsp;&nbsp;修改昵称</span>
                            <div style="display: flex; margin: 20px 0">
                                <input type="text" v-model="username" class="inputstyle" >
                                <div class="userSubmit" @click="userSubmitFn(1)">
                                    提交
                                </div>
                            </div>
                             <span style="border-left: 3px solid #4C94FF;font-weight:bold">&nbsp;&nbsp;修改密码</span>
                            <div style=" margin: 20px 0;display: flex">
                                <div style="display:flex;flex-direction:column; width:200px;">
                                    <input type="password" style="padding:5px 10px" autocomplete='off' v-model="password" class="inputstyle"  placeholder="请输入原始密码">
                                    <input type="password" style="margin-top:20px;padding:5px 10px" autocomplete='off' v-model="new_password" class="inputstyle" placeholder="请输入新密码">
                                </div>

                                <div class="userSubmit" style="margin:40px;" @click="userSubmitFn(3)">
                                    立即修改
                                </div>
                            </div>
                            <span style="border-left: 3px solid #4C94FF ;font-weight:bold">&nbsp;&nbsp;绑定收货地址</span>
                            <div style=" margin-top: 20px">
                                <textarea class="inputstyle" style="padding: 10px;box-sizing: border-box;resize: none;" name="" id="" cols="30" rows="10" v-model="express" placeholder="收货地址，名字，电话"></textarea>
                                <div class="userSubmit" @click="userSubmitFn(2)">
                                    提交
                                </div>
                            </div>
                        </div>

                    </div>

                    </div>
            </div>
        </div>
</template>

<script>
    import api from '../lnit/api'
    import axios from 'axios'
    export default {
        name: "setUser",
        data(){
            return{
                username:null,
                express:null,
                fileList:[],
                url_prefix:null,
                userList:{
                    avatar: null,
                },
                formDate:null,
                password:null,
                new_password:null,
            }
        },
        created() {
          this.url_prefix = api.url_prefix
            this.getUserFn()
        },
        methods:{
            getUserFn(){
              this.$post({
                  url:api.get_user,
              }).then((res)=>{
                  this.userList = res.data.user
                  this.username = res.data.user.nickname
                  this.express =res.data.user.shdz
              })
            },
            userSubmitFn(i){
                if(i ==1){
                    if(!this.username){
                        this.$message({
                            message:'请输入昵称',
                            type:'error'
                        })
                        return
                    }
                    this.$post({
                        url:api.update_user_info,
                        data:{
                            nickname:this.username
                        }
                    }).then((res)=>{
                        this.$message({
                            message:res.data.msg,
                            type:'success'
                        })
                        this.$forceUpdate()
                    })
                }else if(i ==2){
                    if(!this.express){
                        this.$message({
                            message:'请输入收货地址',
                            type:'error'
                        })
                        return
                    }
                    this.$post({
                        url:api.bind_express,
                        data:{
                            shdz:this.express
                        }
                    }).then((res)=>{
                        this.$message({
                            message:"绑定成功",
                            type:'success'
                        })
                        this.getUserFn()
                    })
                }else if(i == 3){
                    this.$post({
                        url:api.update_pwd,
                        data:{
                            password:this.password,
                            new_password:this.new_password
                        }
                    })
                }
            },
            uploadChange(file, fileList){
                this.formDate = new FormData()
                this.formDate.set('file',file.raw)
            },
           async httpRequest(){
             let data =  await axios.post(api.url + api.up_avatar, this.formDate,{
                 headers:{
                     token:localStorage.getItem('token')
                 }
             })
               if(data.data.code == 0){
                   this.$message({
                       message:'修改成功',
                       type:'success'
                   })
                   this.getUserFn()
                   location.reload()
               }
            }
        }
    }
</script>

<style scoped lang="scss">
    .datum-row{
        padding: 10px;
        background-color: #fff;
        .datum-title{
            font-size: 12px;
        }
        .datum{
            display: flex;

        }
    }
    .userSubmit{
        display: inline-block;
        font-size: 12px;
        padding: 5px 10px;
        background-color: #4C94FF;
        color: white;
        border-radius: 22px;
        margin-left: 20px;
        cursor: pointer;
    }
    .inputstyle{
        weight:180px;
    }
    .inputstyle:focus{
        outline: none;
        border-color: #4C94FF;
    }
    .photo{
        width: 120px;
        height: 120px;
        background: url("../assets/avatar.webp") no-repeat;
        background-size: cover;
        border-radius: 50%;
    }
</style>
